<template>
	<v-banner
		v-if="status?.enabled && status?.banner"
		dark
		sticky
		color="primary"
		icon="mdi-update"
		lines="one"
		class="ml-12"
	>
		<v-banner-text class="font-weight-bold ml-n2">
			{{ status.bannerText }}
		</v-banner-text>

		<template #actions="{ dismiss }">
			<v-btn
				absolute
				icon
				right
				color="white"
				class="mt-8 mr-0"
				@click="dismiss"
			>
				<v-icon size="md">
					mdi-close
				</v-icon>
			</v-btn>
		</template>
    </v-banner>
</template>

<script>
export default {
	name: "StatusBanner",

	props: {
		status: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			statusColorEnum: ["success", "info", "warning", "warning", "error"],
			statusEnum: ["operational", "under_maintenance", "degraded_performance", "partial_outage", "major_outage"]
		};
	}
};
</script>
